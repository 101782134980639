import "@fontsource/montserrat";
import "@fontsource/montserrat/400.css";
import "@fontsource/montserrat/700.css";
import "@fontsource/montserrat/800.css";
import React from "react";

import { graphql, navigate, useStaticQuery } from "gatsby";
import { Helmet } from "react-helmet";

import { HeroCards } from "../components/hero-cards";
import { Container, Wrapper } from "../components/layout";
import { StyledButtonPill } from "../components/layout/button";
import { Layout } from "../components/layout/layout";
import { ServiceCards } from "../components/service-cards";
import { FromBottom } from "../components/transitions";

import "../styles/index.css";

const Index = () => {
  const data = useStaticQuery(graphql`
    query {
      spinning: file(relativePath: { eq: "spinning.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      crossfit: file(relativePath: { eq: "crossfit.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      massage: file(relativePath: { eq: "massage.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      hero: file(relativePath: { eq: "home.jpeg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <Layout
      background={data.hero}
      title="Centre Léveil, VOTRE santé, NOTRE mission"
      cta
    >
      <Helmet>
        <title>Centre Léveil</title>
      </Helmet>
      <ServiceCards data={data} />
      <Wrapper>
        <FromBottom>
          <Container direction="column">
            <h1>Notre mission</h1>
            <p>
              Chez nous, au Centre Léveil, notre mission est d’amener la
              population de la ville de Saint-Georges vers un mode de vie sain
              et actif. Nos services ont été choisis spécifiquement pour vous
              aider à atteindre VOS objectifs dans un même endroit. Nous sommes
              convaincus que le Centre Léveil est une corde à ajouter à votre
              arc. La force de notre communauté saura vous aider à dépasser vos
              limites et à améliorer la qualité de VOTRE vie.
            </p>
            <StyledButtonPill
              onClick={() => navigate("/abonnement")}
              style={{ marginTop: "2rem" }}
            >
              Rejoignez-nous!
            </StyledButtonPill>
          </Container>
        </FromBottom>
      </Wrapper>
      <HeroCards />
    </Layout>
  );
};

export default Index;
