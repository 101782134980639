import React from "react";

import styled from "@emotion/styled";
import clsx from "clsx";
import { navigate } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import { isMobile } from "react-device-detect";

import { Card, CardContainer } from "../card";
import { Container, Wrapper } from "../layout";

const Title = styled.h3`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  margin-bottom: 0;

  @media (max-width: 500px) {
    font-size: 1.7rem;
  }
`;

const StyledCard = styled(Card)`
  outline: 2px solid white;
  outline-offset: 4px;
  border-radius: 0;
  transition: all 0.2s ease-in-out;

  &:hover {
    outline-color: var(--color-primary);
    cursor: pointer;
    border-radius: 1rem;
  }
`;

const StyledBackgroundImage = styled(BackgroundImage)`
  width: 100%;
  border-radius: 0;
  overflow: hidden;
  transition: all 0.2s ease-in-out;

  &:hover {
    border-radius: 1rem;
  }
`;

const onClick = () => navigate("/contact");

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ServiceCards = ({ data }: { data: any }) => {
  const { spinning, crossfit, massage } = data;
  return (
    <Wrapper>
      <Container direction="column">
        <CardContainer className={isMobile ? "mobile" : ""}>
          <StyledCard
            className={clsx("card-1", isMobile ? "mobile" : "")}
            onClick={onClick}
          >
            <StyledBackgroundImage
              Tag="div"
              fluid={crossfit.childImageSharp.fluid}
            >
              <Title>Crossfit</Title>
            </StyledBackgroundImage>
          </StyledCard>
          <StyledCard
            className={clsx("card-2", isMobile ? "mobile" : "")}
            onClick={onClick}
          >
            <StyledBackgroundImage
              Tag="div"
              fluid={spinning.childImageSharp.fluid}
              style={{ width: "100%" }}
            >
              <Title>Spinning</Title>
            </StyledBackgroundImage>
          </StyledCard>

          <StyledCard
            className={clsx("card-3", isMobile ? "mobile" : "")}
            style={{ width: "100%" }}
            onClick={onClick}
          >
            <StyledBackgroundImage
              Tag="div"
              fluid={massage.childImageSharp.fluid}
            >
              <Title>Massothérapie</Title>
            </StyledBackgroundImage>
          </StyledCard>
        </CardContainer>
      </Container>
    </Wrapper>
  );
};
