import React from "react";

import clsx from "clsx";
import { StaticImage } from "gatsby-plugin-image";
import { isMobile } from "react-device-detect";

import { CardContainer, Card, CardContent } from "../card";
import { Container, Wrapper } from "../layout";

export const HeroCards = () => {
  return (
    <Wrapper>
      <Container direction="column">
        <h1>Nos valeurs</h1>
        <CardContainer className={isMobile ? "mobile" : ""}>
          <Card className={clsx("card-1", isMobile ? "mobile" : "")}>
            <StaticImage
              src="../../assets/community.jpg"
              alt="community"
              className="hero"
            />
            <CardContent className="content">
              <h2>Communauté</h2>
              <hr />
              <p>
                Une de nos principales forces est sans aucun doute, l’esprit de
                communauté ! Que tu sois débutant ou avancé, dès que tu entres
                dans le Centre Léveil tu te sentiras respecté(e), accueilli(e)
                et encadré(e) autant par les membres que par les coachs et
                professionnels.
              </p>
            </CardContent>
          </Card>
          <Card className={clsx("card-2", isMobile ? "mobile" : "")}>
            <StaticImage
              src="../../assets/passion.jpg"
              alt="passion"
              className="hero"
            />
            <CardContent className="content">
              <h2>Passion</h2>
              <hr />
              <p>
                Le Centre Léveil est construit par des propriétaires passionnés.
                Nous savons que la passion est un sentiment très puissant qui
                peut vous amener à vous surpasser et à devenir meilleur. C’est
                pour cette raison que la passion est l’une de nos valeurs
                principales ! (la passion nous coule dans les veines)
              </p>
            </CardContent>
          </Card>
          <Card className={clsx("card-3", isMobile ? "mobile" : "")}>
            <StaticImage
              src="../../assets/consistency.jpg"
              alt="consistency"
              className="hero"
            />
            <CardContent className="content">
              <h2>Constance</h2>
              <hr />
              <p>
                La constance est la clé pour obtenir des résultats et maintenir
                un mode de vie sain et actif. Nous croyons fortement que nos
                services vous aideront à développer cette force. Nous voulons
                que «prendre soin de soi» devienne un mode de vie !
              </p>
            </CardContent>
          </Card>
        </CardContainer>
      </Container>
    </Wrapper>
  );
};
