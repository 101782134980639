import styled from "@emotion/styled";

export const CardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 2.4rem;
  grid-row-gap: 0px;
  grid-auto-rows: 1fr;
  grid-template-areas: "card-1 card-2 card-3";

  .card-1 {
    grid-area: card-1;
  }
  .card-2 {
    grid-area: card-2;
  }
  .card-3 {
    grid-area: card-3;
  }

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, 1fr);
    grid-template-areas:
      "card-1"
      "card-2"
      "card-3";

    .card-1 {
      grid-area: card-1;
    }

    .card-2 {
      grid-area: card-2;
    }

    .card-3 {
      grid-area: card-3;
    }

    @media (max-width: 500px) {
      max-width: 90%;
      margin: auto;
    }
  }
`;

export const Card = styled.div`
  background-color: transparent;
  border-radius: 0.8rem;
  width: 100%;
  display: grid;
  grid-template-columns: minmax(300px, calc(30vw));
  grid-template-rows: 300px auto;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  grid-auto-rows: 1fr;
  grid-template-areas:
    "hero"
    "content";

  .hero {
    grid-area: hero;
  }
  .content {
    grid-area: content;
  }

  @media (max-width: 900px) {
    margin-right: 0;
    margin-bottom: 2rem;
  }

  img {
    max-height: 300px;
  }

  h2 {
    margin-bottom: 1.2rem;

    @media (max-width: 500px) {
      font-size: 1.8rem;
    }
  }

  hr {
    display: flex;
    border: 0;
    &:before {
      content: "";
      display: block;
      width: 6rem;
      border-top: 1.2rem solid var(--color-primary);

      @media (max-width: 500px) {
        border-top: 1rem solid var(--color-primary);
      }
    }
  }

  p {
    margin-bottom: 0;
  }
`;

export const CardContent = styled.div`
  padding: 2.4rem 2.6rem;
  text-align: left;
  color: var(--color-text-light);
  background-color: var(--color-wrapper-light-2);

  @media (max-width: 500px) {
    padding: 1rem 1.6rem;
  }
`;
